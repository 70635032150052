<template>
    <div class="maincontent">
        <div class="container">
            <div class="row">
                <div class="col-12 headerPic" style="height:500px">
                    <!-- Vueper Slide paralax einfügen -->
                    <vueper-slides ref="myVueperSlides" fixedHeight=600px :parallax="-1" :parallax-fixed-content="false">
                        <vueper-slide
                            v-for="(slide, i) in slides" :key="i"
                            :image="require(`@/assets/${slide.image}`)" 
                            :style="'min-height: 100%'"/>
                        </vueper-slides>
                    <!-- <div class="withBackgroundImage d-flex justify-content-center"></div> -->
                </div>
            </div>
            <div class="row d-flex justify-content-center description">
                <div class="col-12 col-md-8">
                    <h3>Baubeschreibung</h3>
                    <p class="text-muted">
                        Es entstehen 32 Mietwohnungen nach KfW 55* in der Größe von 25 qm
                        (1-Zimmer-Wohnung) bis 114 qm (4-Zimmer-Wohnung). Davon sind 8 Wohnungen barrierefrei - 
                        diese sind mit B gekennzeichnet, z. B. WE 09 B. Alle Wohnungen werden über 3 Treppenhäuser mit Personenlift ab
                        dem Untergeschoß bis zum Staffelgeschoß erschlossen.
                    </p>
                    <h3>Fertigstellung</h3>
                    <p class="text-muted">
                        Die Arbeiten an den verschiedenen Bauabschnitte werden zu unterschiedlichen Zeiten im Laufe des Jahres 2021 abgeschlossen.
                        Die Wohnungen WE_01 bis WE_10 befinden sich in Haus 1 und sind bis Mitte April 2021 bezugsfertig.
                        Die Fertigstellung von Haus 2 mit den Wohnungen WE_11B bis WE_21 folgt dann zum 1. Mai 2021. Haus 3 mit den 
                        Wohnungen WE_22 bis WE_32B soll dann zum 15. Mai 2021 fertiggestellt werden.
                    </p>
                    <h3>Allgemeine Ausstattung</h3>
                    <p class="text-muted">
                        Das Gebäude ist unterkellert. Im Untergeschoss befinden sich den Wohnungen zugeordnete Kellerabteile,
                        sowie 29 Kfz-Abstellplätze, wovon 3 Abstellplätze
                        mit Kfz-Ladestation ausgestattet sind. Des Weiteren sind im Untergeschoss die technischen Betriebsräume für die Versorgung der
                        Wohnungen mit Energie, Heizung (Fernwärme), Wasser, Kommunikation etc. untergebracht. 
                    </p>
                    <h3>Wohnungen</h3>
                    <p class="text-muted">
                        Alle Wohungen sind mit einer Fußbodenheizung und mit Eichenpakett in den Wohnräumen ausgestattet. 
                        Die Badezimmer sind alle gefließt und an eine zentrale Warmwasserversorgung angeschlossen.
                        Abhängig von der Größe der Badezimmer befinden sich darin eine Dusche und/oder eine Badewanne sowie
                        ein WC und ein Waschtisch. Darüber hinaus kann im Badezimmer eine Waschmaschine aufgestellt werden.
                        In jeder Wohnung ist eine Einbauküche mit Kühl- und Gefrierschrank sowie eine Geschirrspülmaschine verbaut.
                        Die Innentüren sind aus Holz und in weiß gehalten. Alle Wände und Decken sind weiß gestrichen.
                        Der gesamte Komplex ist an das Glasfasernetz angeschlossen und jeder Raum mit einem Kat 6 Anschluss versehen.
                    </p>
                    <p class="text-muted">
                        Die Wohnungen erhalten Kunstofffenster mit Isolierverglasung, alle EG-Wohnungen
                        sowie die Südfassade, Westfassade und Ostfassade mit elektrischen,
                        außenliegenden Raffstore.
                    </p>
                    <h3>Für Interessenten</h3>
                    <p class="text-muted">
                        Um Ihre Traumwohnung zu finden sehen Sie sich unter Projekt &#10140; Grundrisse (Wohnungen) mit Hilfe der Filtermöglichkeiten 
                        die passenden Wohnungen an und kontaktieren Sie uns über das Kontaktformular.
                        Eine weiterer Weg eine für Sie geeignete Wohnung zu finden besteht darin, dass Sie unter Projekt &#10140; Aussenansicht sich den Komplex ansehen und dann
                        durch Klicken auf die grünen Wohnungsbezeichnungen (z.B. WE_02) zu der entsprechenden Wohnung mit allen wesentlichen Informationen kommen.
                        Um einen besseren Eindruck von den Wohnungen zu bekommen, finden Sie unter Projekt &#10140; Musterwohnung einen virtuellen Rundgang durch eine 3-Zimmer-Wohnung,
                        hier exemplarisch die Wohnung WE_29.
                    </p>
                    <small class="text-muted" style="font-size:0.75rem">
                        * Energieausweis wird nach der Fertigstellung erstellt <br>
                    </small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
export default {
  name: 'Description',
  components: {
    VueperSlides, VueperSlide
  },
    data (){
        return {
            search: true,
            filter: '',
            selectOption: '',
            selectLength: '',
            slides:[
                {
                    image:"JVS90_Perspektiven.jpg",
                    title: 'Slide 1',
                    content: 'Slide 1 content.'
                }
            ]
        }
    },
    methods:{
        
    },
    computed:{
        
    },
    created(){
    }

}
</script>

<style scoped>
@media(max-width:576px){
    .headerPic{
        padding: 0 !important
    }
}
.headerPic{
    padding-left: 15px;
    padding-right: 15px;
}
.description{
    margin-top: 150px;
    font-size: 1.2rem;
}
</style>